import React from "react";

import { useShop } from "../../context/ShopContext";
import Icon from "./Icon";

const SocialMedia = () => {
  const { social_accounts: accounts } = useShop();

  if (accounts?.length < 1) return null;

  return (
    <ul className="social-media list-inline mb-0">
      {accounts.map((account) => {
        const { name, url: link, icon } = account;

        return (
          <li className="list-inline-item" key={link.url}>
            <a href={link.url} target={link.target} title={name}>
              <Icon icon={icon} />
              <span className="visually-hidden">{name}</span>
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default SocialMedia;
