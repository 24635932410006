import React from "react";

import {
  siteLang as defaultLocale,
  siteCurrency as defaultCurrency,
} from "../../config/site-config";

const Currency = ({
  value,
  noZeroDecimals,
  locale = defaultLocale,
  currency = defaultCurrency,
}) => (
  <>
    {new Intl.NumberFormat(locale, {
      style: "currency",
      minimumFractionDigits: noZeroDecimals ? 0 : undefined,
      currency,
    }).format(Number(value || 0))}
  </>
);

export default Currency;
