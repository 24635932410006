import React from "react";
import { Link } from "gatsby";

const Anchor = (props) => {
  const {
    children,
    href,
    target,
    title,
    className,
    "aria-label": ariaLabel,
    rel,
  } = props;

  // Return a span element when href is not provided
  if (!href) {
    return (
      <span
        title={title}
        className={`anchor-empty ${className || ""}`}
        aria-label={ariaLabel}
      >
        {children}
      </span>
    );
  }

  // On external links use a simple anchor element
  if (href?.match(/^\s?http(s?)/gi)) {
    return (
      <a
        href={href}
        target={target}
        title={title}
        className={`anchor-link ${className || ""}`}
        aria-label={ariaLabel}
        rel={rel}
      >
        {children}
      </a>
    );
  }

  // On internal links use gatsby link component
  return (
    <Link
      to={href}
      target={target}
      title={title}
      className={`anchor-gatsby ${className || ""}`}
      aria-label={ariaLabel}
      rel={rel}
    >
      {children}
    </Link>
  );
};

export default Anchor;
