/* Link Resolver
 *  -------------
 * The Link Resolver will help you build your project URL routes and manage
 * links in field types such as a Link or a Rich Text in a Gatsby project.
 *
 * See: https://prismic.io/docs/technologies/link-resolver-gatsby
 */

exports.linkResolver = (doc) => {
  switch (doc.type) {
    case "product": {
      return `/products/${doc.uid}`;
    }
    case "page": {
      return `/pages/${doc.uid}`;
    }
    case "chef": {
      const category = doc.data.category.uid ? `/${doc.data.category.uid}` : "";

      return `${category}/${doc.uid}`;
    }
    case "all_products": {
      return `/${doc.uid}`;
    }
    case "all_chefs": {
      return `/${doc.uid}`;
    }
    case "suppliers": {
      return `/${doc.uid}`;
    }
    case "category": {
      return `/${doc.uid}`;
    }
    case "sign_up": {
      return `/${doc.uid}`;
    }
    default:
      return "/";
  }
};
