import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import { Button, Container, NavDropdown } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";

import { useShop } from "../../context/ShopContext";
import Anchor from "../elements/Anchor";
import Navigation from "../elements/Navigation";
import { Account, HumburgerMenu } from "../../helpers/Icons";
import { useCustomer } from "../../context/CustomerContext";

import "../../styles/components/blocks/header.scss";

const Header = () => {
  const { body, logo, short_title: shortTitle, signUp } = useShop();
  const { handleLogout, isLoggedIn } = useCustomer();
  const mainNav = body.filter((slice) => slice.slice_type === "nav_item");

  return (
    <Navbar
      as="header"
      bg="white"
      sticky="top"
      expand="lg"
      className="site-header"
    >
      <Container fluid>
        <Navbar.Brand as={Anchor} href="/">
          <GatsbyImage image={logo.gatsbyImageData} alt={logo.alt || ""} />
          <span className="visually-hidden">{shortTitle}</span>
        </Navbar.Brand>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Navigation list={mainNav} />
        </Navbar.Collapse>
        <Button
          as={Anchor}
          href={signUp}
          className="mx-auto ms-sm-auto me-md-2 px-3 px-sm-5 fs-6 fw-bold"
        >
          ΕΓΓΡΑΦΗ
        </Button>
        {isLoggedIn() ? (
          <NavDropdown
            as="button"
            menuVariant="dark"
            align="end"
            id="accountDropdown"
            className="dropdown--account"
            title={
              <>
                <Account />
                <span className="visually-hidden">Λογαριασμός</span>
              </>
            }
          >
            <NavDropdown.Item as={Anchor} href="/account/dashboard/">
              Λογαριασμός
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item role="button" onClick={handleLogout}>
              Αποσύνδεση
            </NavDropdown.Item>
          </NavDropdown>
        ) : (
          <Anchor href="/account/login/" className="nav-icon">
            <Account color="var(--bs-base)" />
            <span className="visually-hidden">Σύνδεση στον λογαριασμό</span>
          </Anchor>
        )}
        <Navbar.Toggle aria-controls="responsive-navbar-nav">
          <HumburgerMenu />
        </Navbar.Toggle>
      </Container>
    </Navbar>
  );
};

export default Header;
