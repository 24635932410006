import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { RichText } from "prismic-reactjs";

import { Col, Container, Nav, Row } from "react-bootstrap";

import { useShop } from "../../context/ShopContext";
import Anchor from "../elements/Anchor";
import AnchorPrismic from "../elements/AnchorPrismic";
import SocialMedia from "../elements/SocialMedia";
import NewsletterForm from "../elements/NewsletterForm";
import { ArrowDownBig } from "../../helpers/Icons";

import "../../styles/components/blocks/footer.scss";

const Footer = () => {
  const {
    footer_top_banner: topBanner,
    footer_top_banner_paragraph: topBannerParagraph,
    footer_top_title: topTitle,
    title,
    logo,
    footer_menu: menu,
    footer_menu_2: menu2,
    copyright,
    newsletter_content: newsletterContent,
    newsletter_image: newsletterImage,
  } = useShop();

  const menuItems = menu.map((item) => (
    <Nav.Item
      as={Anchor}
      href={item.link.url}
      target={item.link.target}
      className="mb-2 link-unstyled"
      key={item.label + item.link.url}
    >
      {item.label}
    </Nav.Item>
  ));

  const menu2Items = menu2.map((item) => (
    <Nav.Item
      as={Anchor}
      href={item.link.url}
      target={item.link.target}
      className="mb-2 link-unstyled"
      key={item.label + item.link.url}
    >
      {item.label}
    </Nav.Item>
  ));

  return (
    <footer className="site-footer">
      <Container className="text-center py-6">
        <h2 className="display-4 fw-normal mb-2">{topTitle}</h2>
        <ArrowDownBig />
      </Container>
      <section className="site-footer__banner position-relative py-4 py-sm-5 py-md-6 py-lg-10">
        <GatsbyImage
          image={topBanner.gatsbyImageData}
          className="gatsby-background-image-element"
          alt={topBanner.alt || ""}
        />
        <Container>
          <p
            className="display-4 mb-0 px-3 px-sm-0 fw-normal text-primary text-center"
            dangerouslySetInnerHTML={{ __html: topBannerParagraph }}
          />
        </Container>
      </section>
      <section className="pt-5">
        <Container>
          <Row>
            <Col xs={12} md={3}>
              <GatsbyImage
                image={logo.gatsbyImageData}
                alt={logo.alt || title}
                className="site-logo mb-5"
              />
            </Col>
            {menu.length > 0 && (
              <Col xs={6} md={3}>
                <Nav as="nav" className="flex-column mb-5">
                  {menuItems}
                </Nav>
              </Col>
            )}
            {menu2.length > 0 && (
              <Col xs={6} md={3}>
                <Nav as="nav" className="flex-column mb-5">
                  {menu2Items}
                </Nav>
              </Col>
            )}
            <Col>
              <div className="text-center mb-5">
                {newsletterImage.gatsbyImageData && (
                  <GatsbyImage
                    image={newsletterImage.gatsbyImageData}
                    alt={newsletterImage.alt || "Newsletter"}
                  />
                )}
                <RichText
                  render={newsletterContent.richText}
                  serializeHyperlink={AnchorPrismic}
                />
                <NewsletterForm />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-2 text-light bg-dark">
        <Container>
          <div className="d-flex align-items-center justify-content-center flex-wrap gap-3">
            <small className="flex-fill text-center text-md-start">
              © {new Date().getFullYear()}
              {", "}
              {copyright}
              {" | "}
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a
                href="https://think-plus.gr/"
                title="Think Plus | creative &amp; software agency"
                target="_blank"
                className="link-light link-unstyled"
              >
                Κατασκευή E-Shop Think Plus
              </a>
            </small>
            <SocialMedia />
          </div>
        </Container>
      </section>
    </footer>
  );
};

export default Footer;
