import React, { useState } from "react";
import { useCart } from "react-use-cart";
import Flatpickr from "react-flatpickr";
import { Greek } from "flatpickr/dist/l10n/gr";

import { Form, Spinner } from "react-bootstrap";

import { useShop } from "../../context/ShopContext";
import { ArrowDownSmall } from "../../helpers/Icons";
import { dateFormat, getFullDay } from "../../helpers";
import { functionsBase } from "../../../config/site-config";

// eslint-disable-next-line import/no-extraneous-dependencies
import "flatpickr/dist/themes/material_green.css";

const DatePicker = ({ size, subscription, date, preHandler }) => {
  const { active_delivery_days: activeDeliveryDays, dates_range: datesRange } =
    useShop();
  const { updateCartMetadata, metadata } = useCart();
  const progress = metadata?.signUpProgress || null;
  let fpDateFormat = "l (ΕΝΑΡΞΗ j F)";

  let nextDeliveryDate = null;
  if (date && date.toString() !== "Invalid Date") {
    // We use toDateString() to "reset" the time at 0 and have a clean date
    const today = new Date(new Date().toDateString());
    const days = 7 + date.getDay() - today.getDay();

    // toISOString returns a UTC±00:00 date,
    // in greece the day at 00:00:00 is converted to the previous day at 22:00:00
    // ex: ("2022-01-13T00:00:00Z").toISOString() = 2022-01-12T22:00:00.000Z
    nextDeliveryDate = new Date(
      today.setTime(today.getTime() + 24 * 60 * 60 * 1000 * days)
    ).toISOString();

    fpDateFormat = "l, j M Y";
  }

  const [dateValue, setDateValue] = useState(nextDeliveryDate || metadata.date);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();

  const activeDays = activeDeliveryDays?.map((item) => item.day);
  const datesWeeks = Number(datesRange?.substring(0, 1));
  const errorMessages = {
    missing_id: "Δεν έχετε επιλέξει συσκευασία",
    update_failed: "Παρουσιάστηκε κάποιο πρόβλημα",
    error: "Παρουσιάστηκε κάποιο πρόβλημα",
  };

  const handleChange = (_date) => {
    if (typeof preHandler === "function") {
      const shouldReturn = preHandler();
      if (shouldReturn) return null;
    }

    setMessage(null);
    setLoading(true);

    const subscriptionId = subscription || metadata.subscription.id;
    const data = {
      subscriptionId,
      params: {
        metadata: {
          date: dateFormat(_date, { dateStyle: "full" }),
          internal_date: Date.parse(_date).toString(),
        },
      },
    };

    fetch(`${functionsBase}/update-subscription`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.subscription) {
          setDateValue(_date);
          if (progress) {
            updateCartMetadata({
              date: _date,
              signUpProgress: {
                ...progress,
                delivery: metadata.time ? "complete" : "active",
                menu: metadata.time ? "active" : "pending",
              },
            });
          }
        }
        if (res.error) {
          setMessage(errorMessages[res.error.code]);
        }
      })
      .catch(() => setMessage(errorMessages.error))
      .finally(() => setLoading(false));

    return null;
  };

  let arrowClasses = "";
  let controlClasses = "";
  switch (size) {
    case "lg": {
      arrowClasses = "m-2";
      controlClasses = "form-select-lg";
      break;
    }
    default: {
      arrowClasses = "m-1";
      controlClasses = "";
    }
  }

  return (
    <Form.Group
      className="position-relative"
      controlId="signupForm.deliveryDates"
    >
      <Form.Label className="visually-hidden">Ημερομηνία παράδοσης</Form.Label>
      <Flatpickr
        value={dateValue}
        options={{
          locale: Greek,
          dateFormat: fpDateFormat,
          minDate: new Date().fp_incr(2),
          maxDate: new Date().fp_incr(7 * datesWeeks + 1),
          enable: [(_date) => activeDays?.includes(getFullDay(_date))],
        }}
        onChange={(_date) => handleChange(_date)}
        render={({ defaultValue, value, ...props }, ref) => (
          <Form.Control
            type="text"
            size={size}
            className={`mb-2 text-primary text-uppercase bg-white rounded-0 form-select ${controlClasses}`}
            placeholder="Επιλέξτε ημερομηνία"
            defaultValue={defaultValue}
            isInvalid={message}
            disabled={loading}
            readOnly
            ref={ref}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          />
        )}
      />
      <span
        className={`position-absolute end-0 ${arrowClasses} bg-white pe-none`}
        style={{ top: size === "lg" ? "3px" : "2px" }}
        aria-hidden="true"
      >
        {loading ? (
          <Spinner animation="grow" size="sm" className="mx-1 mt-1" />
        ) : (
          <ArrowDownSmall />
        )}
      </span>
      {message && (
        <Form.Control.Feedback type="invalid">{message}</Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default DatePicker;
