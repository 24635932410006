import { siteLang as defaultLocale } from "../../config/site-config";

const getMonday = (date) => {
  const d = new Date(date);
  const day = d.getDay();
  const diff = d.getDate() - day + (day === 0 ? -6 : 1);

  return new Date(d.setDate(diff));
};

export default (locale = defaultLocale) => {
  const currentDate = new Date();
  const currentMonday = getMonday(currentDate);
  const nextMonday = new Date(
    currentDate.setDate(
      currentDate.getDate() + ((7 - currentDate.getDay()) % 7) + 1
    )
  );
  const showMonday =
    new Date().getDay() <= 4 ? new Date(currentMonday) : new Date(nextMonday);

  return new Intl.DateTimeFormat(locale, {
    weekday: "long",
    month: "numeric",
    day: "numeric",
  })
    .format(showMonday)
    .replace(",", "");
};
