import React, { useState } from "react";
import { useCart } from "react-use-cart";

import { Form, Spinner } from "react-bootstrap";

import { useShop } from "../../context/ShopContext";
import { ArrowDownSmall } from "../../helpers/Icons";
import { functionsBase } from "../../../config/site-config";

const TimePicker = ({ size, time, subscription, preHandler }) => {
  const { time_slots: timeSlots } = useShop();
  const { updateCartMetadata, metadata } = useCart();
  const progress = metadata?.signUpProgress || null;

  const [timeValue, setTimeValue] = useState(time || metadata.time);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();

  const errorMessages = {
    missing_id: "Δεν έχετε επιλέξει συσκευασία",
    update_failed: "Παρουσιάστηκε κάποιο πρόβλημα",
    error: "Παρουσιάστηκε κάποιο πρόβλημα",
  };

  const handleChange = (_time) => {
    if (typeof preHandler === "function") {
      const shouldReturn = preHandler();
      if (shouldReturn) return null;
    }

    setMessage(null);
    setLoading(true);

    const subscriptionId = subscription || metadata.subscription.id;
    const data = {
      subscriptionId,
      params: {
        metadata: {
          time: _time,
        },
      },
    };

    fetch(`${functionsBase}/update-subscription`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.subscription) {
          setTimeValue(res.subscription.metadata.time);
          if (progress) {
            updateCartMetadata({
              time: _time,
              signUpProgress: {
                ...progress,
                delivery: metadata.date ? "complete" : "active",
                menu: metadata.date ? "active" : "pending",
              },
            });
          }
        }
        if (res.error) {
          setMessage(errorMessages[res.error.code]);
        }
      })
      .catch(() => setMessage(errorMessages.error))
      .finally(() => setLoading(false));

    return null;
  };

  let arrowClasses = "";
  switch (size) {
    case "lg": {
      arrowClasses = "m-2";
      break;
    }
    default: {
      arrowClasses = "m-1";
    }
  }

  return (
    <Form.Group className="position-relative">
      <Form.Select
        size={size}
        // eslint-disable-next-line prettier/prettier
        className={`mb-2 ${timeValue ? "text-primary" : "text-muted"} rounded-0`}
        value={timeValue || "ΕΠΙΛΕΞΤΕ ΩΡΑ"}
        onChange={(e) => handleChange(e.target.value)}
        isInvalid={message}
        required
      >
        <option value="ΕΠΙΛΕΞΤΕ ΩΡΑ" hidden disabled>
          ΕΠΙΛΕΞΤΕ ΩΡΑ
        </option>
        {timeSlots?.map((item) => (
          <option value={item.slot} key={item.slot}>
            {item.slot}
          </option>
        ))}
      </Form.Select>
      <span
        className={`position-absolute end-0 ${arrowClasses} bg-white pe-none`}
        style={{ top: size === "lg" ? "3px" : "2px" }}
        aria-hidden="true"
      >
        {loading ? (
          <Spinner animation="grow" size="sm" className="mx-1 mt-1" />
        ) : (
          <ArrowDownSmall />
        )}
      </span>
      {message && (
        <Form.Control.Feedback type="invalid">{message}</Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default TimePicker;
