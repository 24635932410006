import React from "react";

import Nav from "react-bootstrap/Nav";

import NavItem from "../slices/NavItem";

const Navigation = ({ list }) => {
  if (list?.length < 1) return null;

  const items = list.map((navSlice) => (
    <NavItem slice={navSlice} key={navSlice.id} />
  ));

  return (
    <Nav as="nav" className="me-auto">
      {items}
    </Nav>
  );
};

export default Navigation;
