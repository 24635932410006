import React from "react";
import Anchor from "../elements/Anchor";

import { useShop } from "../../context/ShopContext";
import { truncate } from "../../helpers";
import { ArrowRight } from "../../helpers/Icons";

const AnnouncementBar = () => {
  const {
    announcement_enable: enable,
    announcement_text: text,
    announcement_link: link,
  } = useShop();

  if (!enable) return null;

  const content = link.url ? (
    <Anchor href={link.url} target={link.target} title={truncate(text, 40)}>
      {text}
      <ArrowRight size={22} />
    </Anchor>
  ) : (
    text
  );

  return (
    <div
      className="site-announcement p-2 fs-7 text-center text-white bg-primary"
      role="banner"
    >
      {content}
    </div>
  );
};

export default AnnouncementBar;
