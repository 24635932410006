import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import MetaSite from "./MetaSite";
import MetaSocial from "./MetaSocial";
import SchemaWebsite from "./SchemaWebsite";
import SchemaOrganization from "./SchemaOrganization";

const SEO = ({
  title,
  shortTitle,
  description,
  image,
  url,
  siteUrl,
  lang,
  logoUrl,
}) => {
  const config = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          shortTitle
          description
          logoUrl
          siteUrl
          siteLang

          defaultImage
          defaultImageWidth
          defaultImageHeight
          defaultImageAlt
        }
      }
    }
  `);

  const fallback = config.site.siteMetadata;

  const seoTitle = title || fallback.title;
  const seoShortTitle = shortTitle || fallback.shortTitle;
  const seoDescription = description || fallback.description;
  const seoUrl = url || fallback.siteUrl;
  const seoSiteUrl = siteUrl || fallback.siteUrl;
  const seoLang = lang || fallback.siteLang;
  const seoLogoUrl = logoUrl || fallback.logoUrl;
  const seoImage = image || {
    url: fallback.defaultImage,
    alt: fallback.defaultImageAlt,
    dimensions: {
      height: fallback.defaultImageHeight,
      width: fallback.defaultImageWidth,
    },
  };

  return (
    <>
      <MetaSite
        title={seoTitle}
        description={seoDescription}
        image={seoImage}
        url={seoUrl}
        siteUrl={seoSiteUrl}
        lang={seoLang}
      />
      <MetaSocial
        title={seoTitle}
        shortTitle={seoShortTitle}
        description={seoDescription}
        image={seoImage}
        url={seoUrl}
      />
      <SchemaWebsite
        title={seoTitle}
        description={seoDescription}
        image={seoImage}
        url={seoUrl}
        siteUrl={seoSiteUrl}
      />
      <SchemaOrganization
        name={fallback.shortTitle}
        alternateName={fallback.title}
        url={fallback.siteUrl}
        logo={seoLogoUrl}
      />
    </>
  );
};

export default SEO;
