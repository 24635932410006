import React from "react";

import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";

import Anchor from "../elements/Anchor";
import Icon from "../elements/Icon";

const NavItem = ({ slice }) => {
  if (!slice?.primary) return null;

  const { nav_label: label, nav_link: link, nav_icon: icon } = slice.primary;
  const childs = slice.items;
  const hasChilds =
    childs?.length > 0 &&
    childs[0].nav_second_label &&
    childs[0].nav_second_link.url;

  const body = (
    <div className="d-inline-flex flex-lg-column align-items-center justify-content-end h-100">
      <Icon icon={icon} alt={label} />
      {label}
    </div>
  );

  return (
    <Nav.Item className="d-lg-flex align-items-end">
      {hasChilds ? (
        <NavDropdown title={body}>
          {childs.map((item) => {
            if (!item.nav_second_link.url) return null;

            return (
              <NavDropdown.Item
                key={item.nav_second_label + item.nav_second_link}
                as={Anchor}
                href={item.nav_second_link.url}
                target={item.nav_second_link.target}
              >
                {item.nav_second_label}
              </NavDropdown.Item>
            );
          })}
        </NavDropdown>
      ) : (
        <Nav.Link
          as={Anchor}
          href={link.url}
          target={link.target}
          className="h-100"
        >
          {body}
        </Nav.Link>
      )}
    </Nav.Item>
  );
};

export default NavItem;
