import React from "react";
import { Button } from "react-bootstrap";

const NewsletterForm = () => (
  <form>
    <div className="input-group">
      <label htmlFor="newsletter_email" className="form-control">
        <span className="visually-hidden">*E-mail</span>
        <input
          type="email"
          name="email"
          id="newsletter_email"
          className="form-control px-0 border-0"
          placeholder="*E-mail"
        />
      </label>
      <Button type="submit" className="btn btn-primary rounded-2">
        Submit
      </Button>
    </div>
  </form>
);

export default NewsletterForm;
