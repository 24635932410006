import React from "react";
import Anchor from "./Anchor";
import { linkResolver } from "../../utils/prismic-linkResolver";

export default (type, element, content, children, index) => {
  if (!element) return null;

  const { data } = element;
  const { link_type: linkType } = data;

  // Link to an internal prismic document
  if (linkType === "Document") {
    return (
      <Anchor
        href={linkResolver(data)}
        key={data.id}
        className="anchor-prismic--document"
      >
        {content}
      </Anchor>
    );
  }

  // Link to an internal prismic media file
  if (linkType === "Media") {
    return (
      <a
        href={data.url}
        className="anchor-prismic--media"
        target="_blank"
        rel="noreferrer"
        key={data.start + data.end}
      >
        {content}
      </a>
    );
  }

  // Link to an external url
  if (linkType === "Web") {
    const relvalue = data.target === "_blank" ? "noreferrer" : "";

    return (
      <a
        href={data.url}
        className="anchor-prismic--web"
        target={data.target}
        rel={relvalue}
        key={data.start + data.end}
      >
        {content}
      </a>
    );
  }

  return null;
};
