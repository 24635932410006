import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import SVG from "react-inlinesvg";

import Spinner from "react-bootstrap/Spinner";

const Icon = ({ icon, className, alt = "" }) => {
  if (!icon) return null;
  const isSvg = /.(svg)$/i.test(new URL(icon.url).pathname);

  return isSvg ? (
    <SVG
      src={icon.url}
      title={icon.alt || alt}
      loader={<Spinner animation="grow" variant="light" />}
      className={className}
    />
  ) : (
    <GatsbyImage
      image={icon.gatsbyImageData}
      className={className}
      alt={icon.alt || alt}
    />
  );
};

export default Icon;
