import React, { useState, useRef } from "react";
import { navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useCart } from "react-use-cart";

import {
  Button,
  Col,
  Container,
  Modal,
  Overlay,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";

import Anchor from "../elements/Anchor";
import DatePicker from "../elements/DatePicker";
import TimePicker from "../elements/TimePicker";
import {
  ChevronDown,
  Pen,
  ArrowLeft,
  ArrowRightShort,
  Denied,
  Info,
} from "../../helpers/Icons";
import { Currency, dateFormat } from "../../helpers";
import { createItemsMetadata } from "../../helpers/helpers";
import { functionsBase } from "../../../config/site-config";

import "../../styles/components/blocks/cart-bar.scss";

const CartBar = () => {
  const { items, totalItems, removeItem, metadata } = useCart();

  const box = metadata?.box || null;

  const [showDetails, setShowDetails] = useState(false);
  const [activeTab, setActiveTab] = useState("main");
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const checkoutTarget = useRef(null);

  if (!box || totalItems < 1) return null;

  // Create line items - only thumbnails
  const cartItemsThumbnails = items.map((item) => {
    const { image } = item.media[0];

    return (
      <li className="cart-bar__line-item-thumb me-2" key={item.id}>
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.alt ? image.alt : ""}
        />
      </li>
    );
  });

  // Create line items - full details
  const cartItems = items.map((item) => {
    const { image } = item.media[0];

    return (
      <li
        className="cart-bar__line-item mb-2 pb-2 border-bottom border-dark"
        key={item.id}
      >
        <figure className="cart-bar__line-item-thumb">
          <GatsbyImage
            image={image.gatsbyImageData}
            alt={image.alt ? image.alt : ""}
          />
        </figure>
        <Anchor
          href={item.url}
          className="cart-bar__line-item-title link-unstyled d-block fw-bold lh-sm"
        >
          {item.title} <ArrowRightShort />
          <br />
          <small className="text-muted">Γεύματα {item.quantity * 2}</small>
        </Anchor>
        <Button
          variant="link"
          className="p-1"
          onClick={() => removeItem(item.id)}
        >
          <Denied />
          <span className="visually-hidden">Αφαίρεση</span>
        </Button>
      </li>
    );
  });

  // Checkout
  const handleCheckout = () => {
    setMessage(null);

    if (!metadata?.date) {
      setMessage("Επιλέξτε ημερομηνία παράδοσης");
      return null;
    }

    if (!metadata?.time) {
      setMessage("Επιλέξτε ώρα παράδοσης");
      return null;
    }

    setCheckoutLoading(true);

    const data = {
      subscriptionId: metadata.subscription.id,
      params: {
        metadata: {
          items: createItemsMetadata(items),
        },
      },
    };

    fetch(`${functionsBase}/update-subscription`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.subscription) {
          setShowDetails(false);
          setActiveTab("main");
          navigate("/checkout");
        }
        if (res.error) {
          setMessage("Παρουσιάστηκε πρόβλημα");
        }
      })
      .catch((error) => setMessage("Παρουσιάστηκε πρόβλημα"))
      .finally(() => setCheckoutLoading(false));

    return null;
  };

  return (
    <>
      <aside className="cart-bar position-sticky bottom-0 start-0 w-100 bg-primary">
        <Container fluid>
          <Row>
            <Col md={6} className="bg-light">
              <ul className="d-flex list-unstyled m-0 py-2 overflow-auto">
                {cartItemsThumbnails}
              </ul>
            </Col>
            <Col md={6} className="cart-bar__actions px-lg-5 bg-primary">
              <div className="d-flex align-align-items-center justify-content-end">
                <Button
                  variant="light"
                  className="cart-bar__summary me-2 my-2 py-1 px-3"
                  onClick={() => setShowDetails(!showDetails)}
                >
                  <span className="fw-bold text-uppercase">
                    {dateFormat(metadata.date, {
                      weekday: "short",
                      day: "2-digit",
                      month: "short",
                    })}
                  </span>{" "}
                  <del>
                    <Currency value={box.compareAtPrice} />
                  </del>{" "}
                  <Currency value={box.price} />
                  <div>
                    {totalItems * 2} γεύματα κανονική συσκευασία
                    <span
                      className="cart-bar__summary-icon"
                      data-open={showDetails ? "true" : "false"}
                    >
                      <ChevronDown />
                    </span>
                  </div>
                </Button>
                <Button
                  ref={checkoutTarget}
                  variant="danger"
                  className="cart-bar__checkout my-2 fw-bold text-white"
                  onClick={handleCheckout}
                  disabled={checkoutLoading}
                >
                  {checkoutLoading ? (
                    <>
                      <Spinner size="sm" animation="grow" /> ΣΕ ΕΞΕΛΙΞΗ
                    </>
                  ) : (
                    "ΟΛΟΚΛΗΡΩΣΗ"
                  )}
                </Button>
                <Overlay
                  target={checkoutTarget.current}
                  show={!!message}
                  placement="top"
                >
                  <Tooltip>{message}</Tooltip>
                </Overlay>
              </div>
            </Col>
          </Row>
        </Container>
      </aside>
      <Modal
        className="modal--cart-bar text-primary"
        centered
        show={showDetails}
        onHide={() => setShowDetails(false)}
        onExited={() => setActiveTab("main")}
      >
        <Modal.Body>
          {/* Modal tab: Main */}
          {activeTab === "main" && (
            <>
              <Button
                variant="outline-primary"
                className="cart-bar__action d-flex mb-4 px-2 rounded-0 w-100"
                onClick={() => setActiveTab("datetime")}
              >
                <span className="flex-fill">
                  {dateFormat(metadata.date, {
                    weekday: "long",
                    day: "2-digit",
                    month: "2-digit",
                  })}
                </span>
                <span className="small">
                  <Pen size={12} />
                  edit
                </span>
              </Button>
              <h2 className="h5 mb-4">Οι συνταγές μου</h2>
              <ul className="cart-bar__line-items list-unstyled mb-3">
                {cartItems}
              </ul>
              <div className="cart-bar__modal-info mb-4 p-2 text-dark bg-info">
                <Info />
                <div>
                  <p className="small fw-bold mb-2 lh-1">
                    Τιμή ανά μερίδα: <del>5.00€</del> 2.50€
                  </p>
                  <p className="small m-0">
                    Προσθέστε 1 ακόμη συνταγή για την καλύτερη τιμή ανά γεύμα
                  </p>
                </div>
              </div>
              <h2 className="h5 mb-4">Περίληψη παραγγελίας</h2>
              <p className="d-flex justify-content-between mb-1 text-body">
                <span>Συνταγές ({totalItems})</span>
                <span>
                  <Currency value={box.price} />
                </span>
              </p>
              <p className="d-flex justify-content-between mb-0 fw-bold">
                <span>{box.discount}% Έκπτωση</span>
                <span>
                  -<Currency value={box.compareAtPrice - box.price} />
                </span>
              </p>
            </>
          )}

          {/* Modal tab: Edit datetime */}
          {activeTab === "datetime" && (
            <>
              <Button
                variant="outline-primary"
                className="cart-bar__action d-flex justify-content-center mb-4 rounded-0 w-100"
                onClick={() => setActiveTab("main")}
              >
                <ArrowLeft size={22} />
                &nbsp;ΠΙΣΩ
              </Button>
              <div className="mt-2 mb-4">
                <h2 className="h5">Ημέρομηνία</h2>
                <DatePicker />
                <h2 className="h5 mt-4">Ώρα</h2>
                <TimePicker />
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CartBar;
