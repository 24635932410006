import React from "react";
import { Helmet } from "react-helmet";

import { siteLang, shortTitle } from "../../../config/site-config";

import SEO from "../meta/SEO";
import AnnouncementBar from "../blocks/AnnouncementBar";
import Header from "./Header";
import Footer from "./Footer";
import CartBar from "./CartBar";

import "../../styles/main.scss";

const Layout = ({
  children,
  disableAnnouncement = false,
  disableHeader = false,
  disableFooter = false,
  disableCartBar = false,
}) => (
  <>
    <Helmet defaultTitle={shortTitle}>
      <html lang={siteLang} />
      <link rel="dns-prefetch" href="https://fonts.googleapis.com" />
      <link rel="dns-prefetch" href="https://fonts.gstatic.com" />
      <link rel="dns-prefetch" href="https://m.stripe.network" />
      <link rel="dns-prefetch" href="https://js.stripe.com" />
      <link rel="dns-prefetch" href="https://www.google-analytics.com" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <SEO />

    <div className="d-flex flex-column min-vh-100">
      {!disableAnnouncement && <AnnouncementBar />}
      {!disableHeader && <Header />}

      <main className="site-main d-flex flex-column flex-fill">{children}</main>

      {!disableFooter && <Footer />}
      {!disableCartBar && <CartBar />}
    </div>
  </>
);

export default Layout;
