/*
 * Truncate a string until a certain limit
 *
 * @param {string} text - The text to be truncated
 * @param {number} limit - Number
 * @returns {string} A new string containing the specified part of the given string.
 */
export const truncate = (text, limit) => text.substring(0, limit);

/*
 * Get the level of the heading from a prismic richtext field
 *
 * @param {object} title - An object that contains the property richText with a property type
 * @returns {string} A new string containing the heading level.
 */
export const headingLevel = (title) => title.richText[0].type?.slice(-1);

/*
 * Get the weekday of a date in long text format
 *
 * @param {date} date - A date object
 * @param {string} [locale="en-US"] - A locale parameter to specify the language
 * @returns {string} A new string containing the day in full text format.
 */
export const getFullDay = (date, locale = "en-US") =>
  new Intl.DateTimeFormat(locale, {
    weekday: "long",
  }).format(date);

/*
 * Check if an email is valid
 *
 * @param {string} email - The email address to check
 * @returns {boolean} True/false for the validity of the given email
 */
export const isValidEmail = (email) =>
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    email
  );

/*
 * Check if a number is a valid Greek phone
 *
 * The number must NOT have any whitespace and can have an optional country
 * prefix +30 or 0030.
 *
 * @param {string} number - The phone number to check
 * @returns {boolean} True/false for the validity of the given input
 */
export const isValidPhone = (phone) => /^(\+30|0030)?\d{10}$/.test(phone);

/*
 * Parse metadata items from stripe
 *
 * @param {string} data - The metadata string retrieved from stripe with all items
 * @returns {object} Parsed items
 */
export const parseItemsMetadata = (data) => {
  const items = data.replaceAll("i:", '"i":').replaceAll("q:", '"q":');
  return JSON.parse(`[${items}]`);
};

/*
 * Construct items for stripe metadata
 *
 * @param {array} list - A list of items
 * @returns {string} Metadata string to be sent to stripe
 */
export const createItemsMetadata = (list) => {
  const itemMeta = list.map(
    (product) =>
      `{i:"${product.id || product.uid}",q:${
        product.quantity || product.data?.quantity
      }}`
  );
  return itemMeta.join(",");
};
