import React from "react";
import { Helmet } from "react-helmet";

const MetaSite = ({ title, description, image, url, siteUrl, lang }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {image && <meta property="image" content={image.url} />}
      <link rel="canonical" href={url} />
      <link rel="alternate" href={url} hrefLang={lang} />
      <link type="text/plain" rel="author" href={`${siteUrl}/humans.txt`} />
    </Helmet>
  );
};

export default MetaSite;
